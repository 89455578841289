<!-- eslint-disable vue/no-unused-components -->
<template>
  <div class="reports-view">
    <div class="dashboard-report">
      <the-tabs>
        <b-tab-item :label="$t('reports.tab.title.dashboard')">
          <div class="horizontal-layout reportFilter">
            <div class="clientNameFilter">
              <h4>{{ $t("filters.orderClientName") }}</h4>
              <multiselection-control :items="clientList" :selectedItems="clientControlFilterList" textBinding="clientName" keyBinding="clientId" @change="changeOrderClientFilter" width="13rem"></multiselection-control>
            </div>
            <custom-datepicker :defaultRange="rangePicker" @rangeChange="onDatePickerRangeChanged" />
            <b-button size="is-small" type="is-primary" @click="onFilterReportsData">{{ $t("btn.apply") }}</b-button>
          </div>
          <div class="dashboard-report">
            <div class="dashboard-row-container">
              <div class="container-box-shadow">
                <h4 class="bold margin-bottom">{{ $t("reports.title.shipmentsCountries", [this.$moment(this.reportRange.startDate).format("MMM YYYY"), this.$moment(this.reportRange.endDate).format("MMM YYYY")]) }}</h4>
                <shipments-countries :dateRange="reportRange" :reportData="reportData?.reportCountriesShipment" />
              </div>
              <div class="container-box-shadow">
                <h4 class="bold margin-bottom">{{ $t("reports.title.shipmentsCountriesArrival", [this.$moment(this.reportRange.startDate).format("MMM YYYY"), this.$moment(this.reportRange.endDate).format("MMM YYYY")]) }}</h4>
                <shipments-countries :dateRange="reportRange" :reportData="reportData?.reportCountriesArrival" />
              </div>
            </div>
            <div class="dashboard-row-container">
              <div class="container-box-shadow">
                <h4 class="bold margin-bottom">{{ $t("reports.title.paymentPeriodSum", [this.$moment(this.reportRange.startDate).format("MMM YYYY"), this.$moment(this.reportRange.endDate).format("MMM YYYY")]) }}</h4>
                <payment-period-sum :dateRange="reportRange" :reportData="reportData?.reportShipmentsPayments" />
              </div>
              <div class="container-box-shadow">
                <h4 class="bold margin-bottom">{{ $t("reports.title.averageSumReport", [this.$moment(this.reportRange.startDate).format("MMM YYYY"), this.$moment(this.reportRange.endDate).format("MMM YYYY")]) }}</h4>
                <shipments-average-sum-report :dateRange="reportRange" :reportData="reportData?.reportShipmentsSum" />
              </div>
            </div>
            <div class="dashboard-row-container">
              <div class="container-box-shadow">
                <h4 class="bold margin-bottom">{{ $t("reports.title.shipmentsUnitedReport", [this.$moment(this.reportRange.startDate).format("MMM YYYY"), this.$moment(this.reportRange.endDate).format("MMM YYYY")]) }}</h4>
                <b-switch v-model="isSwitchedCompletedShipments" size="is-small">{{ $t("report.shipments.finished") }}</b-switch>
                <shipments-united-report-v-2 :dateRange="reportRange" :reportData="reportData?.reportShipments" :isSwitchedCopmleted="isSwitchedCompletedShipments" />
              </div>
            </div>
            <div class="dashboard-row-container">
              <div class="container-box-shadow">
                <div class="report-header">
                  <h4 class="bold margin-bottom">{{ $t("reports.title.clientBalanceReport") }}</h4>
                  <b-button v-if="false" type="is-primary is-small" icon-right="download" class="margin-left customButton" @click="exportFile()" />
                </div>
                <client-balance-report v-if="false" :dateRange="reportRange" :reportData="reportData?.reportBalance" />
                <h3 style="color: #2c3e50; text-align: center">{{ $t("txt.inDevelopment") }}</h3>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$t('reports.tab.title.summary')">
          <div class="container-box-shadow full-width">
            <div class="report-header">
              <h4 class="bold margin-bottom">{{ $t("reports.title.contactSummaryReport") }}</h4>
              <div class="report-data-header">
                <div class="horizontal-layout reportFilter">
                  <div class="clientNameFilter">
                    <h4>{{ $t("filters.orderClientName") }}</h4>
                    <multiselection-control :items="clientList" :selectedItems="clientControlFilterList" textBinding="clientName" keyBinding="clientId" @change="changeSummaryReportClientFilter" width="13rem"></multiselection-control>
                  </div>
                </div>
                <div style="display: flex">
                  <b-button type="is-primary is-small" :icon-right="isAccordionOpen ? 'fa-chevron-up' : 'fa-chevron-down'" class="margin-left customButton" @click="openAccordion" />
                  <b-button type="is-primary is-small" icon-right="download" class="margin-left customButton" @click="exportFileSummaryReport()" />
                </div>
              </div>
            </div>
            <client-summary-report ref="summaryReport" :reportData="reportProducts" />
            <b-loading :is-full-page="false" v-model="isSumRepLoading"></b-loading>
            <!-- <h3 style="color: #2c3e50; text-align: center">{{ $t("txt.inDevelopment") }}</h3> -->
          </div>
        </b-tab-item>
      </the-tabs>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import TheTabs from "@/components/TheTabs.vue";
import ClientBalanceReport from "@/components/reports/ClientBalanceReport.vue";
import ClientSummaryReport from "@/components/reports/ClientSummaryReport.vue";
import PaymentPeriodSum from "@/components/reports/PaymentPeriodSum.vue";
import ShipmentsCountries from "@/components/reports/ShipmentsCountries.vue";
import ShipmentsUnitedReportV2 from "@/components/reports/ShipmentsUnitedReportV2.vue";
import ShipmentsAverageSumReport from "@/components/reports/ShipmentsAverageSumReport.vue";
import { read, utils, writeFileXLSX } from "xlsx";
import CustomDatepicker from "@/components/commonLib/CustomDatepicker.vue";
import MultiselectionControl from "@/components/commonLib/MultiselectionControl.vue";

import { Chart as ChartJS } from "chart.js";
const oChartFct = (chart, args, options) => {
  if (chart.config.type === "pie") {
    var x = chart.getDatasetMeta(0).data[0]?.x || chart.canvas.clientWidth / 2,
      y = chart.getDatasetMeta(0).data[0]?.y || chart.canvas.clientHeight / 2,
      ctx = chart.ctx;

    ctx.beginPath();
    let outerRadius = chart.getDatasetMeta(0).controller?.outerRadius || 0;
    ctx.arc(x, y, outerRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "#C1C1C110";
    ctx.fill();
  }
};
const radiusBackground = {
  id: "radiusBackground",
  beforeDatasetsDraw: oChartFct,
  onResize: oChartFct
};

ChartJS.register(radiusBackground);

export default {
  components: {
    TheTabs,
    PaymentPeriodSum,
    ClientBalanceReport,
    ClientSummaryReport,
    ShipmentsCountries,
    ShipmentsUnitedReportV2,
    ShipmentsAverageSumReport,
    CustomDatepicker,
    MultiselectionControl
  },
  data() {
    return {
      rangePicker: [this.$moment().startOf("month")._d, this.$moment().endOf("month")._d],
      reportRange: {
        startDate: new Date(2023, 0, 1),
        endDate: new Date(2023, 11, 31)
      },
      isLoading: false,
      isSumRepLoading: false,
      reportData: {},
      isSwitchedCompletedShipments: false,
      isAccordionOpen: true,
      clientList: [],
      clientGroups: [],
      clientControlFilterList: [],
      exportColumns: ["orderInternalId","orderCargoName","orderShipmentType","orderTransportNumber","orderFrom","orderTo","orderRemainingDistance","orderLocation","orderStatusTracing","orderStatusTracingUpdate","orderCommentary",
                "tracing1Plan", "tracing1Fact", "tracing2Plan", "tracing2Fact", "tracing3Plan", "tracing3Fact"
      ]
    };
  },
  created() {
    this.getClientGroup();
  },
  methods: {
    onFilterReportsData() {
      if (this.rangePicker.length < 2) {
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t("report.error.wrongDate"),
          type: "is-warning",
          position: "is-bottom"
        });
        return;
      }
      this.isLoading = true;
      let sStartDate = this.$moment(this.rangePicker[0]).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      let sEndDate = this.$moment(this.rangePicker[1]).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      var selectedClients = this.clientControlFilterList.map((client) => client.clientId);
      const sClientIds = selectedClients.join(',');
      MockService.getReports(sStartDate, sEndDate, sClientIds)
        .then((oResponse) => {
          this.reportData = oResponse.data;
          this.reportRange = {
            startDate: this.rangePicker[0],
            endDate: this.rangePicker[1]
          };
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeSelectedClientName(aValue) {
      const oFilter = {
        "userFilter": {
          "clientIds": aValue
        }
      }
      MockService.setControlFilters("shipmentsTable", oFilter)
          .then(() => {
            this.clientControlFilterList = aValue;
          })
          .catch((error) => {
            this.errorMessage(error.response?.data.errorMessage);
          })
    },
    onGetSummaryProducts() {
      var selectedClients = this.clientControlFilterList.map((client) => client.clientId);
      const sClientIds = selectedClients.join(',');
      MockService.getSummaryReport(sClientIds)
        .then((oResponse) => {
          this.reportProducts = oResponse.data;
          this.isSumRepLoading = false;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onDatePickerRangeChanged(aRange) {
      this.rangePicker = aRange;
    },
    exportFile() {
      
      const wb = utils.table_to_book(document.getElementById("clientTable"), { raw: true });
      wb.Sheets.Sheet1["!cols"].push(...[{ width: 30 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]);
      wb.Sheets.Sheet1["B1"].v = wb.Sheets.Sheet1["B1"].v.replace("\n", " \n");
      wb.Sheets.Sheet1["E1"].v = wb.Sheets.Sheet1["E1"].v.replace("\n", " \n");
      writeFileXLSX(wb, "balance.xlsx");
    },
    exportFileSummaryReport() {
      const XLSX = require("xlsx");
      let wb = null;
      if (this.clientControlFilterList.some((oClient) => oClient.clientId === "b844fadd-4756-11e6-80bf-000c29dca409" || oClient.clientId === "4ec3e060-65df-11e6-80c0-000c29dca409")) {
        const oProduct = this.reportProducts.products.find((oProduct) => oProduct.groupType === "south");
        let aExportColumns = [];
        oProduct.columns.forEach(oColumn => {
          if (oColumn?.name) {
            oColumn.subcolumns.forEach((oSubcolumn)=> {
              if (oColumn.tag === "borderCrossing") {
                aExportColumns.push(oSubcolumn.tag === 'borderName' ? oColumn.name : this.$t("report.balance." + oSubcolumn.tag));
              }  else {
                aExportColumns.push(oColumn.name +" "+ this.$t("report.balance." + oSubcolumn.tag));
              }
              
            })
            
          } else {
            aExportColumns.push(this.$t("report.balance." + oColumn.tag))
          }
        });
        const oTable = this.createTable(aExportColumns, oProduct.data);    
        wb = utils.table_to_book(oTable, { raw: true });
      } else {
        wb = utils.table_to_book(document.getElementById("productTable"), { raw: true });
      }

      const columnKeys = Object.keys(wb.Sheets.Sheet1);
      const columns = columnKeys.filter((key) => key !== "!ref");

      wb.Sheets.Sheet1["!cols"].push({ width: 45 });

      const maxColumnCount = columns.reduce((max, column) => {
        const columnIndex = XLSX.utils.decode_col(column); // преобразуйте буквенный идентификатор столбца в числовой индекс
        return Math.max(max, columnIndex + 1); // индекс + 1 дает количество столбцов, так как индекс начинается с 0
      }, 0);

      for (let i = 0; i < maxColumnCount - 1; i++) {
        wb.Sheets.Sheet1["!cols"].push({ width: 20 });
      }
      writeFileXLSX(wb, "summary_report.xlsx");
    },
    openAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
      this.$refs.summaryReport.openAccordion(this.isAccordionOpen);
    },
    changeOrderClientFilter(aValue) {
      this.changeSelectedClientName(aValue);
      this.onFilterReportsData();
    },
    changeSummaryReportClientFilter(aValue) {
      this.changeSelectedClientName(aValue);
      this.onGetSummaryProducts();
    },
    getClientGroup() {
      MockService.getClientGroup()
        .then((oResponse) => {
          this.clientList = oResponse.data.clientGroup;
          this.getControlFilters();
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getControlFilters() {
      MockService.getControlFilters("shipmentsTable")
        .then((oResponse) => {
          this.clientControlFilterList = oResponse.data?.userFilter ? oResponse.data.userFilter.clientIds : this.clientList;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createTable(columns, data) {
        const table = document.createElement('table');
        // Создание заголовка таблицы
        const thead = document.createElement('thead');
        const headerRow = document.createElement('tr');
        columns.forEach(column => {
            const th = document.createElement('th');
            th.textContent = this.formatTextField(column);
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        table.appendChild(thead);

        // Создание тела таблицы
        const tbody = document.createElement('tbody');
        data.forEach(rowData => {
            const row = document.createElement('tr');
            rowData.orderData.forEach(cellData => {
                const td = document.createElement('td');
                td.textContent = this.formatterTableValue(cellData.value);
                row.appendChild(td);
            });
            tbody.appendChild(row);
        });

        table.appendChild(tbody);
        return table;
    }
  }
};
</script>

<style lang="scss">
.reports-view {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.1rem;
  overflow-y: auto;
  gap: 0.5rem;

  .form-item {
    flex-direction: row;
  }

  .reportFilter {
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;

    .xmx-input:hover,
    .xmx-input:focus {
      border-color: #2c3e50;
    }

    .clientNameFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
  }

  .dashboard-report {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: auto;
    padding: 0.5rem;
    height: 100%;

    .container-box-shadow {
      box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
      padding: 1.2rem;
      display: flex;
      flex-direction: column;
      width: calc(50% - 0.75rem);

      &.full-width {
        width: 100%;
      }
    }

    .dashboard-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      gap: 1.5rem;
      flex-wrap: wrap;
    }

    .report-header {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .report-data-header {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media screen and (max-width: 600px) and (orientation: portrait) {
    .dashboard-report .dashboard-row-container .container-box-shadow {
      width: 99%;
    }
    .reportFilter > .toolbar-panel-right {
      display: grid;
    }
  }

  @media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
    .dashboard-report .dashboard-row-container .container-box-shadow {
      width: 99%;
    }
  }
}
</style>
